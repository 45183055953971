<template>
  <transition name="fade">
    <div class="background">
      <div class="logo"/>
      <div class="titleBlock">
        <div class="title">{{ slideshow.frontMatter.title }}</div>
        <div class="subtitle">{{ slideshow.frontMatter.subtitle }}</div>
        <div class="date">{{ slideshow.frontMatter.date }}</div>
      </div>
      <div class="footer">Confidential / {{ slideshow.frontMatter.title }}</div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'PresentationTitleFrame',
  components: {
  },
  props: {
    slideshow: Object,
  },
};
</script>

<style lang="scss" scoped>

.background {
  background-color: black;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("./images/supergraphics.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;

  .logo {
    height: 4.5rem;
    margin-top: 2rem;
    margin-left: 2rem;
    background-image: url('./images/avalia-logo-white.png');
    // background-image: url('./basler-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .titleBlock {
    margin-top: -14rem;
    margin-left: 8rem;
    font-size: 3rem;
    font-weight: 100;
    line-height: normal;

    .title {
      color: white;
      font-size: 5rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    .subtitle {
      margin-top: 1rem;
    }

    .date {
      margin-top: 1rem;
    }

}

  .footer {
    text-align: right;
    margin: 1rem;
    text-transform: uppercase;
  }

  }

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
