<template>
  <div class="columns" :style="slide.styles">
    <div v-for="(column, columnIndex) in columns" class="ratings" :key="columnIndex">
      <ul>
        <li v-for="(item, itemIndex) in column.slides" :key="itemIndex"
          :class="{ sectionSlide: isSectionTitle(item) }">
          <router-link class="toc-link"
            :to="{path: './' + (columnIndex * slide.itemsPerColumn + itemIndex + 1)}">
            {{ item.title }} {{ isSectionTitle(item) ? '[' + item.pageNumber + ']' : ''}}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableOfContents',
  props: {
    slide: Object,
    slideshow: Object,
  },
  computed: {
    columns() {
      const columns = [];
      let currentColumn;
      this.slideshow.slides.forEach((s, i) => {
        // eslint-disable-next-line no-param-reassign
        s.pageNumber = (i + 1);
        if (i % this.slide.itemsPerColumn === 0) {
          currentColumn = {
            slides: [],
          };
          columns.push(currentColumn);
        }
        currentColumn.slides.push(s);
      });
      return columns;
    },
  },
  mounted() {
    // console.log(this.slide);
  },
  methods: {
    isSectionTitle(slide) {
      if (slide.frame === 'PresentationTitleFrame') return true;
      if (slide.frame === 'SectionTitleFrame') return true;
      if (slide.template === 'TableOfContents') return false;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>

a {
  text-decoration: none;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  color: $avalia-black;
  font-size: 0.8rem;
  line-height: normal;
  overflow: auto;

  .ratings {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    margin: 0rem;

    ul {
      list-style: square;

      .toc-link {
        color: unset;
      }

      li {
        margin-bottom: 0rem;
      }

      li.sectionSlide {
        list-style: none;
        color: $avalia-magenta;
        text-transform: uppercase;
        font-weight: 700;
        margin-left: -1.5rem;
        margin-top: 0.8rem;
        margin-bottom: 0.4rem;
      }
      li.sectionSlide:nth-child(1) {
        margin-top: 0rem;
      }
    }
  }

}

</style>
