<template>
  <div class="content">
    <div class="stats">
      <div class="stats-box" v-for="(stat, index) in slide.stats" :key="index">
        <div class="value">{{ stat.value }}</div>
        <div class="label">{{ stat.label }}</div>
      </div>
    </div>
    <div class="narrative" :style="cssVars" v-html="slide.narrative.text" />
  </div>
</template>

<script>
export default {
  name: 'StatsWithNarrative',
  props: {
    slide: Object,
  },
  computed: {
    cssVars() {
      return {
        '--bullet-font-size': (this.slide.fontSize ? this.slide.fontSize : '0.9rem'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;

  .stats {
    flex-basis: 70%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow:scroll;

    .stats-box {
      background-color: rgba(249, 29, 255, 0.2);
      width: 16rem;
      height: 16rem;
      display: flex;
      flex-grow: 0;
      margin: 1rem;
      flex-direction: column;
      text-align: center;
      justify-content: center;

      .value {
        display: flex;
        font-size: 7rem;
        height: 10rem;
        align-items: center;
        justify-content: center;
      }

      .label {
        display: flex;
        font-size: 1.5rem;
        height: 4rem;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .narrative {
    padding-left: 1rem;
    flex-basis: 30%;
    flex-grow: 1;
    border-left: thin solid;
    font-size: var(--bullet-font-size);

    :deep(p) {
      padding: 0px;
      margin-top: 0;
    }
  }
}
</style>
