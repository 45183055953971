<template>
  <div :style="cssVars" class="table-container black-text">
    <div class="headers-container md-layout">
      <div
        v-for="(header, index) in slide.headers"
        :key="'header-' + index"
        class="header-item md-layout-item"
        :class="columnSize"
      >
        <div
          class="item"
          :class="backgroundColor(header)"
        >
          {{ header.text }}
        </div>
        <div class="icon"><i class=" icon-size" :class="[getColumnIcon(header), textColor(header)]"></i></div>
      </div>
    </div>
    <div class="rows-container md-layout">
      <div
        v-for="(row, index) in slide.rows"
        :key="'row-' + index"
        class="row md-layout-item md-size-100 md-layout"
      >
        <div
          v-for="(cell, index) in row.cells"
          :key="'cell-' + index"
          class="md-layout-item"
          :class="columnSize"
        >
          <div
            class="cell-item item"
            :class="{ 'empty-cell': isEmptyCell(cell) }"
          >
            {{ cell.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    slide: Object,
  },
  data() {
    return {
      defaultBackgroundColor: 'pink-background',
      defaultTextColor: 'pink-text',
    };
  },
  created() {
  },
  computed: {
    cssVars() {
      return {
        '--bullet-font-size': (this.slide.fontSize ? this.slide.fontSize : '1.6rem'),
      };
    },
    columnSize() {
      const nbColumn = this.slide.headers.length;
      const size = Math.floor(100 / nbColumn);
      return `md-size-${size}`;
    },
  },
  methods: {
    backgroundColor(header) {
      return header.color ? `${header.color}-background` : this.defaultBackgroundColor;
    },
    textColor(header) {
      return header.color ? `${header.color}-text` : this.defaultTextColor;
    },
    getColumnIcon(header) {
      return header.icon || 'fa-trophy';
    },
    isEmptyCell(cell) {
      return cell === null || cell === undefined || cell.text === '';
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  width: 100%;
  font-size: 1rem;
}

.headers-container {
  display: flex;
  align-items: center;
  min-height: 5rem;
  padding-top: 2.5rem;

  .header-item {
    position: relative;
    font-weight: bold;
    font-size: 1rem;
    line-height: 2rem;
  }
}

.cell-item {
  background-color: #efefef;
}

.empty-cell {
  background-color: transparent;
}

.item {
  margin: 2px;
  padding: 7px 10px;
  height: calc(100% - 4px);
}

.icon {
  position: absolute;
  right: 10px;
  top: 0;
  margin-top: -1.1rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 5px 5px 11px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 11px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 5px 11px 0px rgba(0,0,0,0.75);
}
.icon-size {
  font-size: 1.5rem;
}

.blue-background {
  background-color: $avalia-blue;
}

.pink-background {
  background-color: $avalia-magenta;
}

.green-background {
  background-color: $avalia-green;
}

.orange-background {
  background-color: $avalia-orange;
}

.red-background {
  background-color: $avalia-red;
}
.black-text {
  color: $avalia-black;
}

.blue-text {
  color: $avalia-blue;
}

.pink-text {
  color: $avalia-magenta;
}

.green-text {
  color: $avalia-green;
}

.orange-text {
  color: $avalia-orange;
}

.red-text {
  color: $avalia-red;
}

/*.success {
  color: green;
  font-size: 7rem;
}

.warning {
  color: orange;
  font-size: 7rem;
}

.danger {
  color: red;
  font-size: 7rem;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  color: $avalia-black;
  font-size: 1.6rem;
  line-height: normal;

  .ratings {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    margin: 1rem;

    ul {
      li {
        margin-bottom: 1.5rem;
      }
    }
  }

  .full-size-image {
    display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 1rem;
    // background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 2rem;
  }

  .styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
  }
  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
  }
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
  }
  .styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
  }

}*/
</style>
