import { render, staticRenderFns } from "./FullSlideImageWithNarrative.vue?vue&type=template&id=25d57fae&scoped=true&"
import script from "./FullSlideImageWithNarrative.vue?vue&type=script&lang=js&"
export * from "./FullSlideImageWithNarrative.vue?vue&type=script&lang=js&"
import style0 from "./FullSlideImageWithNarrative.vue?vue&type=style&index=0&id=25d57fae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d57fae",
  null
  
)

export default component.exports