<template>
  <div class="columns" :style="cssVars">
    <div v-for="(column, index) in slide.columns" :key="index" class="ratings">
        <p class="column-title"><b>{{ column.title.text }}</b></p>
        <p v-for="(item, index) in column.items" :key="index">
          <span v-html="item.text"/>
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContextHypothesisActions',
  props: {
    slide: Object,
  },
  computed: {
    cssVars() {
      return {
        '--bullet-font-size': (this.slide.fontSize ? this.slide.fontSize : '1.6rem'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  color: $avalia-black;
  /* font-size: 1.6rem; */
  font-size: var(--bullet-font-size);

  line-height: normal;

  .column-title {
    font-weight: 900;
    font-size: calc(var(--bullet-font-size) * 1.3);
    color: $avalia-magenta;
    padding-bottom: 0.7rem;
    border-bottom: thin solid $avalia-magenta;
  }

  p {
    margin-bottom: 0px;

    :deep(span) > ul {
        padding-left: 20px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
  }

  .ratings {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    margin-left: 2.5rem;
    margin-right: 2.5rem;

    ul {
      li {
        margin-bottom: 1.5rem;
      }
    }
  }

}

</style>
