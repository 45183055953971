<template>
  <div class="card-content">
    <div class="card-body">
      <div class="left-block">
        <div class="columns">
          <div v-for="(column, index) in slide.columns"
            :key="index"
            class="column">
            <div class="column-header" :style="{ backgroundColor: styles[column.color] }">
              {{column.title}}
            </div>
            <div class="column-body">
              <ul>
                <li v-for="(item, index) in column.items" :key="index" v-html="item.text"></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="recommendations">
          <p v-html="slide.recommendation.text"></p>
        </div>
      </div>
      <div class="right-block">
        <div class="svgContainer" v-html="svgCode" ref="svgContainer"></div>
      </div>
    </div>
    <div v-if="slide.narrative" class="narrative" v-html="slide.narrative.text" />
  </div>
</template>

<script>
import styleVariables from '@/assets/css/styles.scss';
import axios from 'axios';

export default {
  props: {
    slide: Object,
    styles: {
      type: Object,
      default() {
        return styleVariables;
      },
    },
  },
  data() {
    return {
      svgCode: '',
    };
  },
  mounted() {
    axios.get(this.slide.illustration.url)
      .then((response) => {
        this.svgCode = response.data;
        this.$nextTick(() => {
          this.$refs.svgContainer.firstElementChild.setAttribute('width', '100%');
          this.$refs.svgContainer.firstElementChild.setAttribute('height', '100%');
          this.$refs.svgContainer.firstElementChild.setAttribute('preserveAspectRatio', 'xMidYMid meet');
        });
      });
  },
  methods: {
  },
};

</script>

<style lang="scss" scoped>
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-height: 100%;
  overflow: scroll;
  background-colorXXX: $avalia-gray;
  padding: 1rem;

  .card-header {
    display: flex;
    flex-direction: column;
    background-colorXXX: $avalia-blue;
    height: 4rem;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
  }

  .card-body {
    background-colorXXX: $avalia-orange;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    max-height: 100%;
    overflow: scroll;

    .left-block {
      display: flex;
      max-height: 100%;
      flex-direction: column;
      flex-grow: 1;
      background-colorXXX: $avalia-green;
      flex-basis: 70%;

      .columns {
        display: flex;
        max-height: 100%;
        overflow: scroll;
        flex-direction: row;
        flex-grow: 1;
        background-colorXXX: $avalia-orange;
        flex-basis: 70%;

        .column {
          display: flex;
          max-height: 100%;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: 100%;
          margin: 0.2rem;

          .column-header {
            display: flex;
            background-color: $avalia-blue;
            margin: 0.3rem;
            align-items: center;
            justify-content: center;
            padding: 0.6rem;
            font-size: 1.3rem;
            font-weight: 600;
          }
          .column-body {
            display: flex;
            max-height: 100%;
            flex-direction: column;
            flex-grow: 1;
            padding: 0rem;
            background-colorXXX: $avalia-green;
            font-size: 1.1rem;
            ul {
              max-height: 100%;
              // overflow: scroll;
              margin: 0px;
              li {
                padding: 0px;
                margin-top: 0.5rem;
              }
            }
          }
        }
      }

      .recommendations {
        display: flex;
        background-color: $avalia-green;
        padding: 0.2rem;
        // flex-basis: 20%;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        margin-top: 0rem;
        font-weight: 400;
        text-align: center;
        borderXXX: thin solid darkgray;
      }
    }

    .right-block {
      display: flex;
      flex-grow: 1;
      flex-basis: 60%;
      margin-left: 1rem;
      borderXX: thin solid darkgray;
      align-content: center;
      justify-content: center;
      padding: 1rem;

      .svgContainer {
        display: flex;
        flex-grow: 1;

        :deep(svg) {
          width: 100%;
          height: 100%;

          * path {
            cursor: unset;
          }

          * tspan {
            pointer-events: none;
          }
        }
      }

    }

  }

  .narrative {
    padding-left: 1rem;
    flex-basis: 30%;
    flex-grow: 1;
    border-left: thin solid;
    font-size: 0.9rem;

    :deep(p) {
      padding: 0px;
      margin-top: 0;
    }
  }
}
</style>
