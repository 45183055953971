import { render, staticRenderFns } from "./PresentationTitleFrame.vue?vue&type=template&id=521b5b3e&scoped=true&"
import script from "./PresentationTitleFrame.vue?vue&type=script&lang=js&"
export * from "./PresentationTitleFrame.vue?vue&type=script&lang=js&"
import style0 from "./PresentationTitleFrame.vue?vue&type=style&index=0&id=521b5b3e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521b5b3e",
  null
  
)

export default component.exports