<template>
  <iframe
    title="full-slide-frame"
    v-show="showFrame"
    :src="slide.embeddedFrameUrl"
    frameborder="0"
    allowtransparency
    class="full-size"
    @load="onFrameLoaded"/>
</template>

<script>
export default {
  name: 'FullSlideEmbeddedFrame',
  props: {
    slide: Object,
  },
  data() {
    return {
      showFrame: true,
    };
  },
  methods: {
    onFrameLoaded() {
      /*
      setTimeout(() => {
        console.log('grabbing focus');
        this.showFrame = true;
        document.getElementsByClassName("slide")[0].focus(); }, 5000);
      */
    },
  },
};

</script>

<style lang="scss" scoped>

.full-size {
  border: thin solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  background-size: cover;
  background-repeat: none;
}

</style>
