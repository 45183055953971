<template>
  <div class="full-image-slide">
    <div v-if="!hasRequestFailed" class="columns">
      <div v-if="imageLoaded"
        :style="{
          backgroundImage: `url('${image}')`,
          backgroundSize: `${slide.image.backgroundSize}`}"
          class="full-size-image">
      </div>
      <div
        v-if="slide.narrative && slide.narrative.text"
        class="narrative" :style="cssVars"
        v-html="slide.narrative.text"/>
    </div>
    <placeholder v-else />
  </div>
</template>
<script>
import axios from 'axios';
import Placeholder from '@/components/SlideTemplates/basic/Placeholder.vue';

export default {
  name: 'FullSlideImageWithNarrative',
  components: {
    Placeholder,
  },
  props: {
    slide: Object,
  },
  computed: {
    cssVars() {
      return {
        '--bullet-font-size': (this.slide.fontSize ? this.slide.fontSize : '0.9rem'),
      };
    },
    imageLoaded() {
      return this.image != null;
    },
  },
  data() {
    return {
      image: null,
      hasRequestFailed: false,
    };
  },
  mounted() {
    this.fetchImage();
  },
  methods: {
    fetchImage() {
      axios.get(this.slide.image.url, { responseType: 'blob' })
        .then((response) => {
          if (response.status === 204) {
            this.hasRequestFailed = true;
          } else {
            this.image = window.URL.createObjectURL(response.data);
          }
        })
        .catch(() => {
          this.hasRequestFailed = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  .full-size-image {
    display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    flex-basis: 70%;
    flex-grow: 1;
    margin-right: 1rem;
    // background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .narrative {
    padding-left: 1rem;
    flex-basis: 30%;
    flex-grow: 1;
    border-left: thin solid;
    font-size: var(--bullet-font-size);
    // width: min-content;

    :deep(p) {
      padding: 0px;
      margin-top: 0;
    }
  }
}

.error-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 4.5rem;
  line-height: 4.5rem;
  text-align: center;
}

.full-image-slide {
  width: 100%;
  height: 100%;
}
</style>
