<template>
  <div
    class="wrapper">
    <component
      :is="currentSlideFrame"
      :slideshow="slideshow"/>
  </div>
</template>

<script>
import SuperGraphicsFrame from '@/components/SlideFrames/SuperGraphicsFrame.vue';
import PresentationTitleFrame from '@/components/SlideFrames/PresentationTitleFrame.vue';
import SectionTitleFrame from '@/components/SlideFrames/SectionTitleFrame.vue';
import FullScreenFrame from '@/components/SlideFrames/FullScreenFrame.vue';

export default {
  components: {
    PresentationTitleFrame,
    SectionTitleFrame,
    SuperGraphicsFrame,
    FullScreenFrame,
  },
  data() {
    return {
      slideshow: {
        frontMatter: {},
        slides: [{}],
        currentSlideIndex: 0,
        currentSlide: {},
        transitionName: '',
      },
    };
  },
  computed: {
    currentSlideFrame() {
      return this.slideshow.currentSlide.frame || 'SuperGraphicsFrame';
    },
  },
  created() {
    window.addEventListener('keyup', this.onKeyUp);
  },
  destroyed() {
    window.removeEventListener('keyup', this.onKeyUp);
  },
  mounted() {
    const deckName = this.$route.params.deck;
    let deckUrlEncoded;
    if (deckName === 'remote') {
      deckUrlEncoded = this.$route.query.url;
    } else {
      deckUrlEncoded = encodeURIComponent(`/project/slide-decks/${deckName}.json`);
    }

    const targetDeck = this.$store.getters.getDeckByUrlEncoded(deckUrlEncoded);
    if (targetDeck) {
      this.slideshow.frontMatter = targetDeck.frontMatter;
      this.slideshow.slides = targetDeck.slides;
      this.slideshow.currentSlideIndex = Math.min(Math.max(this.$route.params.id, 1), this.slideshow.slides.length);
      this.slideshow.currentSlide = this.slideshow.slides[this.slideshow.currentSlideIndex - 1];
    } else {
      const jwtRelayUrl = this.$route.query.relayUrl;
      this.$store.dispatch('fetchDeck', { deckUrlEncoded, jwtRelayUrl }).then((deck) => {
        this.slideshow.frontMatter = deck.frontMatter;
        this.slideshow.slides = deck.slides;
        this.slideshow.currentSlideIndex = Math.min(Math.max(this.$route.params.id, 1), this.slideshow.slides.length);
        this.slideshow.currentSlide = this.slideshow.slides[this.slideshow.currentSlideIndex - 1];
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.slideshow.currentSlide = this.slideshow.slides[this.slideshow.currentSlideIndex - 1];
    next();
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('keyup', this.onKeyUp);
    next();
  },

  methods: {
    prevSlide() {
      if (this.slideshow.currentSlideIndex > 1) {
        this.slideshow.currentSlideIndex -= 1;
        this.$emit('prevSlide');
        this.$router.push({
          name: 'Slides',
          params: {
            id: this.slideshow.currentSlideIndex,
            transitionName: 'slideprev',
          },
        }).catch(() => { });
      }
    },
    nextSlide() {
      const transitionName = this.slideshow.currentSlideIndex === 1 ? 'fade' : 'slidenext';
      if (this.slideshow.currentSlideIndex < this.slideshow.slides.length) {
        this.slideshow.currentSlideIndex += 1;
        this.$emit('nextSlide');
        this.$router.push({
          name: 'Slides',
          params: {
            id: this.slideshow.currentSlideIndex,
            transitionName,
          },
        }).catch(() => { });
      }
    },
    goToTableOfContent() {
      const transitionName = 'fade';
      this.$emit('goToTableOfContent');
      this.$router.push({ name: 'Slides', params: { id: 3, transitionName } }).catch(() => { });
    },
    fullscreen() {
      document.documentElement.requestFullscreen();
    },
    onKeyUp(event) {
      switch (event.key) {
        case 'ArrowLeft':
          this.prevSlide();
          break;
        case 'ArrowRight':
          this.nextSlide();
          break;
        case 'ArrowUp':
          this.goToTableOfContent();
          break;
        case 'ArrowDown':
          this.$router.go(-1);
          break;
        case 'Escape':
          this.fullscreen();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.wrapper {
  background-color: black;
  position: absolute;
  height: 100%;
  width: 100%;
}

</style>
