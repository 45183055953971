<template>
  <div class="columns" :style="cssVars">
    <div v-for="(column, index) in slide.columns" :key="index" class="ratings">
      <ul>
        <li v-for="(bullet, index) in column.bullets" :key="index">
          {{ bullet.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleColumns',
  props: {
    slide: Object,
  },
  computed: {
    cssVars() {
      return {
        '--bullet-font-size': (this.slide.fontSize ? this.slide.fontSize : '1.6rem'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>

.success {
  color: green;
  font-size: 7rem;
}

.warning {
  color: orange;
  font-size: 7rem;
}

.danger {
  color: red;
  font-size: 7rem;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  color: $avalia-black;
  /* font-size: 1.6rem; */
  font-size: var(--bullet-font-size);

  line-height: normal;

  .ratings {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    margin: 1rem;

    ul {
      li {
        margin-bottom: 1.5rem;
      }
    }
  }

}

</style>
