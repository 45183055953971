<template>
  <div class="columns">
    <div v-if="svgCode && !hasRequestFailed" class="svgContainer" v-html="svgCode" ref="svgContainer"/>
    <placeholder v-else-if="hasRequestFailed" />
    <div
      v-if="slide.narrative && slide.narrative.text"
      class="narrative" :style="cssVars"
      v-html="slide.narrative.text"/>
  </div>
</template>

<script>
import axios from 'axios';
import Placeholder from '@/components/SlideTemplates/basic/Placeholder.vue';

export default {
  name: 'FullSlideSvgWithNarrative',
  components: {
    Placeholder,
  },
  props: {
    slide: Object,
    slideshow: Object,
  },
  data() {
    return {
      svgCode: '',
      hasRequestFailed: false,
    };
  },
  mounted() {
    axios.get(this.slide.svgUrl)
      .then((response) => {
        if (response.status === 204) {
          this.hasRequestFailed = true;
        } else {
          this.svgCode = response.data;
          this.$nextTick(function checkSlideDestruction() {
            // Check if slide destroyed before nextTick
            if (this.$refs.svgContainer) {
              this.$refs.svgContainer.firstElementChild.setAttribute('viewBoxX', '0 0 1000 1000');
              this.$refs.svgContainer.firstElementChild.setAttribute('width', '100%');
              this.$refs.svgContainer.firstElementChild.setAttribute('height', '100%');
              this.$refs.svgContainer.firstElementChild.setAttribute('preserveAspectRatio', 'xMidYMid meet');
              if (this.slide.links) {
                this.slide.links.forEach((link) => {
                  this.$refs.svgContainer.querySelector(link.id).style.pointerEvents = 'all';
                  this.$refs.svgContainer.querySelector(link.id).style.cursor = 'pointer';
                  this.$refs.svgContainer.querySelector(link.id).onclick = () => {
                    const targetSlide = this.slideshow.slides.find((slide) => slide.title === link.target);
                    this.$router.push({ name: 'Slides', params: { id: this.slideshow.slides.indexOf(targetSlide) + 1, transitionName: 'fade' }, query: this.$route.query });
                  };
                });
              }
            }
          });
        }
        // this.svgCode = "<svg class='svgElement' viewBox='0 0 1000 1000'><rect x='10' y='10' width='100' height='100' stroke='blue' fill='transparent' stroke-width='2'/></svg>";
      })
      .catch(() => {
        this.hasRequestFailed = true;
      });
  },
  computed: {
    cssVars() {
      return {
        '--bullet-font-size': (this.slide.fontSize ? this.slide.fontSize : '0.9rem'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;

  .svgContainer {
    display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    flex-basis: 70%;
    flex-grow: 1;
    // border: thin solid red;

    :deep(svg) {
      width: 100%;
      height: 100%;

      * path {
        cursor: unset;
      }

      * tspan {
        pointer-events: none;
      }
    }
  }

  .narrative {
    margin-left: 1rem;
    padding-left: 1rem;
    flex-basis: 30%;
    flex-grow: 1;
    border-left: thin solid;
    font-size: var(--bullet-font-size);
    // width: min-content;

    :deep(p) {
      padding: 0px;
      margin-top: 0;
    }

  }
  .error-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 4.5rem;
    line-height: 4.5rem;
    text-align: center;
  }
}

</style>
