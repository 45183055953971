<template>
  <div class="centered-column">
    <div class="centered">
      <router-link to="/slides/2">Go to Slide 1</router-link>
      <a href="http://avalia.io">Go to Avalia website</a>
      <a href="http://localhost:3000/#/slides/2">Externalized internal link</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hyperlink',
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss" scoped>

.centered-column {
  display: flex;
  flex-grow: 1;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
}

.centered {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  span {
    color: $avalia-black;
    text-align: center;
    font-size: 4rem;
    line-height: normal;
  }
}
</style>
