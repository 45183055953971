<template>
  <div class="view">
    <div class="filters">

      <md-field class="filter">
        <label for="zoomLevel">Zoom</label>
        <md-select
          v-model="selectedZoomLevel">
          <md-option
            v-for="zoomLevel in zoomLevels"
            :value="zoomLevel.key"
            :key="zoomLevel.key"
            name="Zoom"
          >{{ zoomLevel.value }}</md-option>
        </md-select>
      </md-field>

      <!-- from slide deck JSON file -->
      <md-field
        v-for="(filter, index) in slide.vega.filters"
        :key="index"
        class="filter">
        <label for="filter.label">{{ filter.label }}</label>
        <md-select
          v-model="filters[filter.label]">
          <md-option
            v-for="option in filter.options"
            :value="option.key"
            :key="option.key"
            name="Zoom"
          >{{ option.value }}</md-option>
        </md-select>
      </md-field>
      <!-- -->

      <md-field v-if="slide.vega.filterText" class="filter">
        <label for="filterText">Filter text</label>
        <md-input name="filterText" id="filterText" v-model="filterText" />
      </md-field>

      <md-switch v-if="slide.vega.filterText && !slide.vega.hideShowOnlySelectionToggle"
        v-model="hideFiltered">Show only selection</md-switch>

      <div class="pullright">
      <md-button @click="exportImage('svg')" class="md-raised md-primary">
        export SVG
      </md-button>
      <md-button @click="exportImage('png')" class="md-raised md-primary">
        export PNG
      </md-button>
        <md-switch v-model="showNarratives">Show narrative</md-switch>
      </div>
    </div>
    <div class="columns">
      <div
        id="vega"
        @contextmenu.prevent
        class="vega" />
      <div
        v-if="showNarratives"
        class="narrative"
        :style="cssVars"
        v-html="slide.narrative.text" />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-named-default
// import { default as vegaEmbed } from 'vega-embed';
import vega from 'vega-embed';
import * as vegax from 'vega';
// eslint-disable-next-line import/no-extraneous-dependencies
import { interpolateRgbBasis } from 'd3-interpolate';

export default {
  name: 'VegaWithNarrative',
  props: {
    slide: Object,
  },
  data() {
    return {
      vegaComponent: undefined,
      showNarratives: true,
      filters: {},
      filterText: this.slide.vega.filterTextDefault ? this.slide.vega.filterTextDefault : '',
      hideFiltered: false,
      zoomLevels: [
        {
          key: '1',
          value: 'x1',
        },
        {
          key: '1.2',
          value: 'x1.2',
        },
        {
          key: '1.5',
          value: 'x1.5',
        },
        {
          key: '2',
          value: 'x2',
        },
        {
          key: '5',
          value: 'x5',
        },
        {
          key: '10',
          value: 'x10',
        },
        {
          key: '20',
          value: 'x20',
        },
      ],
      selectedZoomLevel: {},
      showLabels: [
        {
          key: '0',
          value: 'Hide',
        },
        {
          key: '0.8',
          value: 'Show',
        },
      ],
    };
  },
  watch: {
    selectedZoomLevel: {
      handler() {
        if (!this.vegaComponent) return;
        this.resize();
      },
    },
    showNarratives: {
      handler() {
        this.$nextTick(() => this.resize());
      },
    },
  },
  destroyed() {
    this.vegaComponent.finalize();
    window.removeEventListener('resize', this.resize);
  },
  mounted() {
    if (this.slide.vega.labelContents) this.labelContents = this.slide.vega.labelContents;
    const spec = this.slide.vega.specUrl;
    const vegaContainer = document.getElementById('vega');

    this.selectedZoomLevel = this.slide.vega.initialZoom;

    // add required header to query for authentication or content negotiation
    // Content-Types for Accept header can be application/json or text/csv
    const loader = {
      target: '_blank',
      http: {
        headers: {
        },
      },
    };
    if (this.jwtToken) {
      loader.http.headers.Authorization = `Bearer ${this.jwtToken}`;
    }
    if (this.slide.vega.dataUrlAccept) {
      loader.http.headers.Accept = this.slide.vega.dataUrlAccept;
    }

    const options = {
      renderer: 'svg',
      loader,
      width: Math.floor((vegaContainer.offsetWidth - 10) * this.slide.vega.initialZoom),
      height: Math.floor((vegaContainer.offsetHeight - 10) * this.slide.vega.initialZoom),
      scaleFactor: 1,
      logLevel: vegax.Warn,
      actions: false,
      patch: (originalSpec) => {
        // eslint-disable-next-line no-param-reassign
        originalSpec.data[0].url = this.slide.vega.dataUrl;
        if (this.slide.vega.data && Array.isArray(this.slide.vega.data)) {
          this.slide.vega.data.forEach((data) => {
            const toOverride = originalSpec.data.find((d) => d.name === data.name);
            if (toOverride) {
              originalSpec.data.splice(originalSpec.data.indexOf(toOverride), 1, data);
            } else {
              originalSpec.data.push(data);
            }
          });
        }
        return originalSpec;
      },
    };

    const interpolateFunction = interpolateRgbBasis(['gray', 'white', 'magenta']);
    vegax.scheme('avaliaColorScheme', interpolateFunction);
    vegax.scheme('avaliaColorSchemeDiscrete', ['#bcc6cc', '#ffa200', '#00d2ff', '#f91dff', '#fe0600', '#27eb68']);

    vega('#vega', spec, options).then((result) => {
      this.vegaComponent = result;
      const signals = this.slide.vega.signals || [];
      signals.forEach((s) => {
        this.vegaComponent.view.signal(s.name, s.value);
      });
      this.vegaComponent.view.addSignalListener('openUrl', (signalName, signalValue) => {
        window.open(signalValue.url, '_blank');
      });
      /*
      this.vegaComponent.view.addSignalListener('height', (signalName, signalValue) => {
        // console.log(`The vega view height has been resized internally: ${signalValue}`);
        // this.resize();
      });
      */

      // This is a bit tricky: we dynamically register watchers on the md-select elements, which
      // are generated based on the definiton in the deck json file
      this.slide.vega.filters.forEach((f) => {
        this.$watch(function doFilterStuff() { return this.filters[f.label]; }, (newValue) => {
          // console.log(this.vegaComponent.view.scenegraph());
          // eslint-disable-next-line no-underscore-dangle
          // this.vegaComponent.view.scenegraph().root._redraw = true;
          // this.vegaComponent.view.resize();
          this.vegaComponent.view
            .signal(f.signal, newValue).runAsync()
            .then(() => {
              this.resize();
            });
        });
        this.$set(this.filters, f.label, f.default);
      });

      if (this.slide.vega.filterText) {
        this.vegaComponent.view.signal('filterText', this.filterText).run();
        this.$watch('filterText', (newValue) => {
          this.vegaComponent.view
            .signal('filterText', newValue).run();
        });
        this.$watch('hideFiltered', (newValue) => {
          this.vegaComponent.view
            .signal('hideFiltered', newValue).run();
        });
      }

      this.resize();
      this.$nextTick(() => {
        this.resize();
        this.$nextTick(() => {
          this.resize();
        });
      });
    });
  },
  created() {
    window.addEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      // console.log('resizing');
      const w = Math.floor(document.getElementById('vega').offsetWidth - 10) * this.selectedZoomLevel;
      const h = Math.floor(document.getElementById('vega').offsetHeight - 10) * this.selectedZoomLevel;
      this.vegaComponent.view
        .signal('width', w)
        .signal('height', h).runAsync()
        .then(() => {
          this.scrollVegaToCenter(w, h);
        });
    },
    scrollVegaToCenter(w, h) {
      const vegaContainer = document.getElementById('vega');
      vegaContainer.scrollTo(
        (w - vegaContainer.offsetWidth) / 2,
        (h - vegaContainer.offsetHeight) / 2,
      );
    },
    exportImage(fileFormat) {
      this.vegaComponent.view.toImageURL(fileFormat, 2).then((url) => {
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `avalia-slides-${this.slide.title}.${fileFormat}`);
        link.dispatchEvent(new MouseEvent('click'));
      });
    },
  },
  computed: {
    jwtToken() {
      return this.$store.getters.getJWT;
    },
    cssVars() {
      return {
        '--bullet-font-size': (this.slide.fontSize ? this.slide.fontSize : '0.9rem'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>

::-webkit-scrollbar {
  display: none;
}

.view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    flex-shrink: 0;
    flex-wrap: wrap;

    .filter {
      width: unset;
      max-width: 120px;
      margin-right: 10px;
      flex-shrink: 1;
    }

    .pullright {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    .vega {
      // display: flex;  // comment this when svg, uncomment when canvas
      // flex-direction: row;
      // justify-content: space-between;

      flex-basis: 70%;
      flex-grow: 1;
      margin-right: 1rem;
      //align-items: center;
      //justify-content: center;
      overflow: scroll;
      -webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */

      :deep(canvas) {
        max-width: unset;
        width: fit-content;
        height: fit-content;
        //object-fit: scale-down;
        //object-position: left;
      }
    }

    .narrative {
      padding-left: 1rem;
      flex-basis: 30%;
      flex-grow: 1;
      border-left: thin solid;
      font-size: var(--bullet-font-size);
      // width: min-content;

      :deep(p) {
        padding: 0px;
        margin-top: 0;
      }
    }
  }
}
</style>
