<template>
  <div class="content">
    <div class="stats">
      <div
        v-for="(score, index) in scores"
        :key="index"
        class="stats-box-wrapper"
        :style="{ 'flex-basis': cardsPerLine() }"
      >
        <div class="stats-box" :style="{ backgroundColor: getScoreColor(score) }">
          <div class="value">
            <i class="icon" :class="getScoreIcon(score)"></i>
            <span class="number">{{ score.value }}</span>
          </div>
          <div class="label">{{ score.label }}</div>
        </div>
      </div>
    </div>
    <div class="narrative" :style="cssVars" v-html="slide.narrative.text" />
  </div>
</template>

<script>
import axios from 'axios';
import * as d3 from 'd3';

export default {
  name: 'ScoreCardWithNarrative',
  props: {
    slide: Object,
  },
  data() {
    return {
      repos: [],
      scores: [],
    };
  },
  methods: {
    cardsPerLine() {
      // const cardsPerLine = 4;
      const cardsPerLine = Math.ceil(Math.sqrt(this.slide.scores.length));
      return `${100 / cardsPerLine}%`;
    },
    getScoreIcon(score) {
      return score.icon;
    },
    getScoreColor() {
      return 'rgba(249, 29, 255, 1)';
      /*
      if (score.value >= 66) {
        return 'rgba(39, 235, 104, 1)';
      }
      if (score.value >= 33) {
        return 'rgba(255, 162, 0, 1)';
      }
      return 'rgba(254, 6, 0, 1)';
      */
    },
  },
  mounted() {
    axios.get('/project/data/github-repos-uber.csv')
      .then((response) => d3.csvParse(response.data))
      .then((repos) => {
        this.repos = repos.map((r) => ({
          ...r,
          createdAt: new Date(r.createdAt),
          updatedAt: new Date(r.updatedAt),
          pushedAt: new Date(r.pushedAt),
          numberOfCommits: parseInt(r.defaultBranchRef_target_history_totalCount, 10),
        }));
        this.scores.push({
          label: 'number of repos',
          value: repos.length,
          icon: 'fab fa-git-alt',
        });
        this.scores.push({
          label: 'max commits',
          value: this.repos.reduce((max, p) => (p.numberOfCommits > max ? p.numberOfCommits : max), this.repos[0].numberOfCommits),
          icon: 'fab fa-git-alt',
        });
        this.scores.push({
          label: 'number of repos',
          value: repos.length,
          icon: 'fab fa-git-alt',
        });
      });
  },
  computed: {
    cssVars() {
      return {
        '--bullet-font-size': (this.slide.fontSize ? this.slide.fontSize : '0.9rem'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  letter-spacing: unset;

  .stats {
    flex-basis: 70%;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    overflow: scroll;

    .stats-box-wrapper:hover {
      /*border: solid rgb(188, 198, 204) 0.3rem;*/
      transform: rotate(-5deg);
      transition: 0.5s;
      transition-timing-function: ease-out;
    }

    .stats-box-wrapper {
      transition: transform 0.2s;
      transition-timing-function: ease-out;

      background-color: white;
      display: flex;
      flex-grow: 0;
      margin: 0rem;
      flex-direction: column;
      flex-basis: 50%;
      padding: 0.25rem;

      .stats-box {
        /*border: solid transparent 0.3rem;*/
        color: white;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;

        .value {
          margin-top: 1.5rem;
          display: flex;
          font-size: 3.5rem;
          margin-left: 1.2rem;
          margin-right: 1.2rem;
          justify-content: space-between;
          font-weight: 900;

          .icon {
            line-height: unset;
            font-size: 2.5rem;
          }

          .number {
          }
        }

        .label {
          padding: 0.8rem;
          margin-top: 1rem;
          display: flex;
          font-size: 1.8rem;
          line-height: 1.4rem;
          font-variant-caps: all-small-caps;
        }
      }
    }
  }

  .narrative {
    padding-left: 1rem;
    flex-basis: 30%;
    flex-grow: 1;
    border-left: thin solid;
    font-size: var(--bullet-font-size);
    :deep(p) {
      padding: 0px;
      margin-top: 0;
    }
  }
}
</style>
