import { render, staticRenderFns } from "./FullText.vue?vue&type=template&id=6781ae9c&scoped=true&"
import script from "./FullText.vue?vue&type=script&lang=js&"
export * from "./FullText.vue?vue&type=script&lang=js&"
import style0 from "./FullText.vue?vue&type=style&index=0&id=6781ae9c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6781ae9c",
  null
  
)

export default component.exports