<template>
  <div class="columns">
    <div class="headings">
      <div class="blank-space"/>
      <div v-for="(heading, index) in slide.headings" :key="index" class="heading">
        {{ heading }}
      </div>
    </div>
    <div v-for="(rating, index) in slide.ratings" :key="index" class="ratings">
      <div class="rating-icon">
        <div>{{ rating.title }}</div>
        <div :class="rating.grade">&#9679;</div>
      </div>
      <div v-for="(comment, index) in rating.comments" :key="index" class="rating">
        <div v-html="comment"/>
      </div>
    </div>
    <div v-if="false" class="narrative">
      {{ slide.narrative.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExecutiveSummary',
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss" scoped>

.success {
  color: $avalia_green;
  font-size: 7rem;
}

.warning {
  color: $avalia_orange;
  font-size: 7rem;
}

.danger {
  color: $avalia_red;
  font-size: 7rem;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;

  .headings {
    flex-basis: 5%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .blank-space {
      margin: 5px;
      height: 8rem;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .heading {
      margin: 0.5rem;
      margin-right: 2rem;
      text-align: right;
      font-size: 1.5rem;
      flex-basis: 130%;
    }
}

  .ratings {
    flex-basis: 25%;
    border-left: thin solid gray;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .rating-icon {
      margin: 5px;
      height: 8rem;
      flex-grow: 0;
      flex-shrink: 0;
      display: grid;
      text-align: center;
      font-size: 1.5rem;
    }

    .rating {
      font-size: 0.9rem;
      line-height: initial;
      margin: 0.5rem;
      margin-left: 1.5rem;
      flex-basis: 130%;
    }
  }

  .narrative {
    padding-left: 1rem;
    flex-basis: 20%;
    flex-grow: 1;
    border-left: thin solid;
    font-size: 0.9rem;
    // width: min-content;

    p {
      padding: 0px;
    }
  }

}

</style>
