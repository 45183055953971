<template>
  <div class="centered-column">
    <div class="centered">
      <div class="quote" :style="slide.style">
        <i class="icon fas fa-quote-left"></i>
        <div v-html="slide.text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss" scoped>

.centered-column {
  display: flex;
  flex-grow: 1;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
}

.centered {
  flex-grow: 1;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;

  .quote {
    display: flex;
    flex-direction: row;
    color: $avalia-black;
    font-size: 4.5rem;
    font-weight: 500;

    .icon {
      color: $avalia-magenta;
      text-align: left;
      font-size: 2.4rem;
      align-self: flex-start;
      margin-right: 1rem;
    }

    div {
      padding-bottom: 8rem;
      text-align: left;
      line-height: normal;

      :deep(p) {
        margin: 0;
      }
    }
  }
}
</style>
