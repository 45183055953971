<template>
  <div class="columns" :style="{fontSize: slide.fontSize}">
    <div v-for="(column, index) in slide.columns" :key="index" class="ratings">
      <ul>
        <li v-for="(bullet, index) in column.bullets" :key="index" v-html="bullet.text">
        </li>
      </ul>
    </div>
    <div
      :style="{ backgroundImage: `url('${slide.image.url}')`,
      backgroundSize: `${slide.image.backgroundSize}`}"
      class="full-size-image"/>
  </div>
</template>

<script>
export default {
  name: 'MultipleColumnsWithImage',
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss" scoped>

.success {
  color: green;
  font-size: 7rem;
}

.warning {
  color: orange;
  font-size: 7rem;
}

.danger {
  color: red;
  font-size: 7rem;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  color: $avalia-black;
  font-size: 1.6rem;
  line-height: normal;

  .ratings {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    margin: 1rem;

    ul {
      li {
        margin-bottom: 1.5rem;
      }
    }
  }

  .full-size-image {
    display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 1rem;
    // background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 2rem;
  }

}

</style>
