<template>
<div class="columns">
  <div class="centered-column">
    <div class="centered">
      <span>{{ slide.text }}</span>
      <div class="image">
        <div class="imageContent"></div>
      </div>
      <ul>
        <li v-for="(bullet, index) in slide.bullets" :key="index">{{ bullet.text }}</li>
      </ul>
    </div>
  </div>
  <div class="centered-column">
    <div class="centered">
      <span>{{ slide.text }}</span>
      <div class="image"></div>
      <ul>
        <li v-for="(bullet, index) in slide.bullets" :key="index">{{ bullet.text }}</li>
      </ul>
    </div>
  </div>
  <div class="centered-column">
    <div class="centered">
      <span>{{ slide.text }}</span>
      <div class="image"></div>
      <ul>
        <li v-for="(bullet, index) in slide.bullets" :key="index">{{ bullet.text }}</li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss" scoped>

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  align-items: flex-start;
  overflow: scroll;

}

.centered-column {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  // border: thin solid green;
    overflow: scroll;
}

.image {
  padding-top: calc(0.25 * 3 * 100%);
  border: thin solid orange;
  background-image: url('https://static.independent.co.uk/s3fs-public/thumbnails/image/2015/06/06/15/Chris-Pratt.jpg?w968h681');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.imageContent {
  width: 100%;
  height: 100%;
  overflow: scroll;
  // border: thin solid red;
  // background-color: yellow;
}

.centered {
  // border: thin solid blue;
  overflow: scroll;
  margin-left: 0.0rem;
  margin-right: 0.0rem;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: left;

  color: $avalia-black;
  font-size: 1.8rem;
  line-height: 200%;
  font-weight: 600;
  color: $avalia-magenta;

  ul {
    width: 100%;
    font-size: 1.4rem;
    margin-top: 0;
    color: $avalia-black;
    font-weight: 400;
    padding-left: 0 rem;
    list-style: square;
    line-height: 150%;
  }
}
</style>
