<template>
  <div class="columns">
    <div
      :style="{ backgroundImage: `url('${slide.image.url}')`}"
      class="full-size-image">
      <div class="explore"><a
        :href="slide.kibanaPermalinkUrl"
        target="_blank">Explore in Kibana</a></div>
    </div>
    <div
      class="narrative"
      v-html="slide.narrative.text"/>
  </div>
</template>

<script>
export default {
  name: 'KibanaEmbedded',
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss" scoped>

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;

  .full-size-image {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    flex-basis: 70%;
    flex-grow: 1;
    margin-right: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: flex-end;

    .explore {
      background: rgba(255, 255, 255, 0.6);
    }
  }

  .narrative {
    padding-left: 1rem;
    flex-basis: 30%;
    flex-grow: 1;
    border-left: thin solid;
    font-size: 0.9rem;
    // width: min-content;

    :deep(p) {
      margin-top: 0px;
      padding: 0px;
    }
  }

}

</style>
