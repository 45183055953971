<template>
  <transition appear name="fade">
    <div class="background" :style="slideshow.currentSlide.styles">
      <div class="logo" />
      <div class="titleBlock">
        <div class="title">{{ slideshow.currentSlide.title }}</div>
      </div>
      <div class="footer">Confidential / {{ slideshow.frontMatter.title }} / {{ slideshow.currentSlideIndex }}</div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SectionTitleFrame',
  components: {},
  props: {
    slideshow: Object,
  },
};
</script>

<style lang="scss" scoped>

.background {
  // filter: grayscale(1);
  background-color: $avalia-black;
  position: absolute;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;

  .logo {
    height: 4.5rem;
    margin-top: 2rem;
    margin-left: 2rem;
    background-image: url("./images/avalia-logo-white.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .titleBlock {
    padding-bottom: 8rem;
    text-align: center;
    line-height: normal;
    color: transparent;
    font-size: 6rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  .footer {
    text-align: right;
    margin: 1rem;
    text-transform: uppercase;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
