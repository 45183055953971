<template>
  <div class="background">
    <div class="slide">
      <div class="header">
        <div class="title">{{ slideshow.currentSlide.title }}</div>
        <div class="logo"/>
      </div>
      <div class="content">
        <transition :name="$route.params.transitionName ? $route.params.transitionName : 'fade'">
          <component
            :is="slideshow.currentSlide.template"
            :slideshow="slideshow"
            :slide="slideshow.currentSlide"
          />
        </transition>
      </div>
      <div class="footer">
        <div class="legend">
          Confidential / {{ slideshow.frontMatter.title }} / {{ slideshow.currentSlideIndex }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BulletList from '@/components/SlideTemplates/basic/BulletList.vue';
import CenteredText from '@/components/SlideTemplates/basic/CenteredText.vue';
import ComparativeScoreCards from '@/components/SlideTemplates/scorecards/ComparativeScoreCards.vue';
import ContextHypothesisActions from '@/components/SlideTemplates/legacy/ContextHypothesisActions.vue';
import Diagram from '@/components/SlideTemplates/legacy/Diagram.vue';
import DiagramWithTwoNarratives from '@/components/SlideTemplates/legacy/DiagramWithTwoNarratives.vue';
import ExecutiveSummary from '@/components/SlideTemplates/legacy/ExecutiveSummary.vue';
import FullSlideImage from '@/components/SlideTemplates/basic/FullSlideImage.vue';
import FullSlideImageWithNarrative from '@/components/SlideTemplates/basic/FullSlideImageWithNarrative.vue';
import FullSlideEmbeddedFrame from '@/components/SlideTemplates/basic/FullSlideEmbeddedFrame.vue';
import FullSlideSvgWithNarrative from '@/components/SlideTemplates/basic/FullSlideSvgWithNarrative.vue';
import FullText from '@/components/SlideTemplates/basic/FullText.vue';
import Hyperlink from '@/components/SlideTemplates/basic/Hyperlink.vue';
import ImageWithBullets from '@/components/SlideTemplates/basic/ImageWithBullets.vue';
import KibanaEmbedded from '@/components/SlideTemplates/legacy/KibanaEmbedded.vue';
import MultipleColumns from '@/components/SlideTemplates/basic/MultipleColumns.vue';
import MultipleColumnsWithImage from '@/components/SlideTemplates/basic/MultipleColumnsWithImage.vue';
import ScoreCardWithNarrative from '@/components/SlideTemplates/scorecards/ScoreCardWithNarrative.vue';
import ScoreCardGitHubWithNarrative from '@/components/SlideTemplates/scorecards/ScoreCardGitHubWithNarrative.vue';
import Table from '@/components/SlideTemplates/basic/Table.vue';
import StatsWithNarrative from '@/components/SlideTemplates/legacy/StatsWithNarrative.vue';
import TableOfContents from '@/components/SlideTemplates/logic/TableOfContents.vue';
import VegaWithNarrative from '@/components/SlideTemplates/vega/VegaWithNarrative.vue';
import Placeholder from '@/components/SlideTemplates/basic/Placeholder.vue';

export default {
  name: 'SuperGraphicsFrame',
  components: {
    BulletList,
    CenteredText,
    ComparativeScoreCards,
    ContextHypothesisActions,
    Diagram,
    DiagramWithTwoNarratives,
    ExecutiveSummary,
    FullSlideEmbeddedFrame,
    FullSlideImage,
    FullSlideImageWithNarrative,
    FullSlideSvgWithNarrative,
    FullText,
    Hyperlink,
    ImageWithBullets,
    KibanaEmbedded,
    MultipleColumns,
    MultipleColumnsWithImage,
    Table,
    ScoreCardWithNarrative,
    ScoreCardGitHubWithNarrative,
    StatsWithNarrative,
    TableOfContents,
    VegaWithNarrative,
    Placeholder,
  },
  props: {
    slideshow: Object,
  },
};
</script>

<style lang="scss" scoped>

.background {
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("./images/supergraphics-rotated.svg");
  background-repeat: no-repeat;
  background-size: 1300px;
  background-position-y: -400px;
  background-position-x: -500px;
}

.slide {
  background-color: white;
  position: absolute;
  left: 10%;
  height: 95%;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-left: thin solid black;
  border-bottom: thin solid black;

  :deep(.highlight) {
    color: $avalia-magenta;
  }

}

.header {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  height: 5rem;

  .title {
    font-size: 2.5rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.1;
  }

  .logo {
    flex-grow: 0;
    width: 10rem;
    background-image: url('./images/avalia-logo-gray.png');
    // background-image: url('./basler-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
  }
}

.content {
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
  top: 0;
  margin-top: 2.8rem;
  display: flex;
}

.footer {
  flex-grow: 0;
  height: 2rem;
  display: flex;
  justify-content: flex-end;

  .legend {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slidenext-enter-active, .slideprev-enter-active {
  transition: all 0.6s ease-out;
}
.slidenext-leave-active, .slideprev-leave-active {
  transition: all 0.6s ease-out;
}
.slidenext-enter  {
  transform: translateX(100%);
}
.slidenext-leave-to {
  transform: translateX(-100%);
}

.slideprev-enter  {
  transform: translateX(-100%);
}
.slideprev-leave-to {
  transform: translateX(100%);
}

.slideup-enter-active, .slideprev-enter-active {
  transition: all 0.6s ease-out;
}
.slideup-leave-active, .slideprev-leave-active {
  transition: all 0.6s ease-out;
}
.slideup-enter  {
  transform: translateY(-100%);
}
.slideup-leave-to {
  transform: translateY(100%);
}

.slideup-enter  {
  transform: translateY(100%);
}
.slideup-leave-to {
  transform: translateY(-100%);
}

.slidedown-enter-active, .slideprev-enter-active {
  transition: all 0.6s ease-out;
}
.slidedown-leave-active, .slideprev-leave-active {
  transition: all 0.6s ease-out;
}
.slidedown-enter  {
  transform: translateY(100%);
}
.slidedown-leave-to {
  transform: translateY(-100%);
}

.slidedown-enter  {
  transform: translateY(-100%);
}
.slidedown-leave-to {
  transform: translateY(100%);
}
</style>
