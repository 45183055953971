<template>
  <div class="centered-column">
    <div class="centered">
      <span>{{ slide.text }}</span>
      <ul>
        <li v-for="(bullet, index) in slide.bullets" :key="index">{{ bullet.text }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BulletList',
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss" scoped>

.centered-column {
  display: flex;
  flex-grow: 1;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
}

.centered {
  margin-left: 2rem;
  margin-right: 2rem;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: left;
  text-align: left;

  color: $avalia-black;
  font-size: 3.2rem;
  line-height: 200%;
  font-weight: 600;
  color: $avalia-magenta;

  ul {
    margin-top: 0;
    color: $avalia-black;
    font-weight: 400;
    padding-right: 3.5rem;
    padding-left: 3.5rem;
    list-style: square;
    line-height: 150%;
  }
}
</style>
